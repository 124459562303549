<template>
  <div>
    <div v-if="isShow == 0">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item>
          <el-breadcrumb-item>退桶单</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button type="success" style="height: 36px" @click="reload">
          <i style="font-size: 18px" class="el-icon-refresh-right"></i>
        </el-button>
      </div>

      <div style="padding: 20px 20px 0; white-space: nowrap">
        <el-row>
          <el-col :span="7">
            <span>创建日期：</span>
            <el-date-picker v-model="t1" type="daterange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-col>
        </el-row>

        <el-row style="margin-top: 10px">
          <el-col :span="4">
            <span>订单状态：</span>
            <el-select v-model="query.order_status" clearable filterable placeholder="订单状态">
              <el-option v-for="item in order_status_options" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-col>

          <el-col :span="7">
            <el-input v-model="query.search" placeholder="输入要查询的地址，联系人，手机号" style="width: 70%"></el-input>
            <el-button type="success" size="mini" style="margin-left: 5px" @click="handleQuery()">
              <div style="display: flex; align-items: center; font-size: 14px">
                <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
                <span style="margin-left: 3px">查询</span>
              </div>
            </el-button>
          </el-col>
        </el-row>
      </div>

      <div style="
        margin: 15px 20px;
        padding: 5px 10px;
        border: 1px solid #eeeeee;
        background-color: #f5fafe;
        display: flex;
        align-items: center;
      ">
        <el-button type="primary" size="mini" @click="handleAdd">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
            <span style="margin-left: 5px">新增订单</span>
          </div>
        </el-button>
        <el-button type="primary" size="mini" @click="dialogFormVisible1 = true">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
            <span style="margin-left: 5px">导出订单</span>
          </div>
        </el-button>
      </div>

      <!-- <div style="
        margin: 0 20px;
        padding: 10px 5px;
        border: 1px solid #eeeeee;
        background-color: #f5fafe;
      ">
        <span style="font-size: 14px">订单状态：</span>
        <el-radio-group v-model="radioBtn" size="medium">
          <el-radio-button style="margin-right: 10px" :label="item" v-for="item in radioBtnes"
            :key="item"></el-radio-button>
        </el-radio-group>
      </div> -->

      <div style="padding: 20px">
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border style="width: 100%"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column sortable label="创建日期">
            <template slot-scope="scope">{{ scope.row.create_time }}</template>
          </el-table-column>
          <el-table-column prop="consignee" label="收货人"> </el-table-column>
          <el-table-column prop="phone" label="联系人"> </el-table-column>
          <el-table-column prop="location" label="地址"> </el-table-column>
          <el-table-column prop="back_bucket" label="桶品牌名"> </el-table-column>
          <el-table-column sortable prop="number" label="数量"> </el-table-column>
          <el-table-column sortable prop="deposit" label="应退金额"> </el-table-column>
          <el-table-column prop="notes" label="买家备注"> </el-table-column>
          <el-table-column prop="notes" label="转派备注"> </el-table-column>
          <el-table-column prop="delivery" label="送货员"> </el-table-column>
          <el-table-column sortable prop="service_time" label="送达日期"> </el-table-column>
          <el-table-column prop="order_status" label="状态"> </el-table-column>
          <!-- <el-table-column sortable prop="verificationDate" label="核销日期"> </el-table-column> -->
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <img style="width: 16px; height: 16px; cursor: pointer" @click="handleEdit(scope.$index, scope.row)"
                :src="require('@/assets/iconImg/logo.png')" />
              <span style="margin: 0 10px"></span>
              <img style="width: 16px; height: 16px; cursor: pointer" @click="handleDelete(scope.$index, scope.row)"
                :src="require('@/assets/iconImg/logo.png')" />
            </template>
          </el-table-column>
        </el-table>
        <!-- <div class="tableBottom">
          <span v-if="isResult" style="">显示第&nbsp;1&nbsp;至&nbsp;{{
            tableFinshNum
          }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span>
          <span v-if="!isResult" style="">显示&nbsp;{{ tableFinshNum }}&nbsp;项结果，共&nbsp;{{
            tableFinshNum
          }}&nbsp;项</span>
          <div style="display: flex; justify-content: flex-end; align-items: center">
            <el-button>首页</el-button>
            <el-button>上页</el-button>
            <el-pagination background layout=" pager,slot" :total="1"></el-pagination>
            <el-button>下页</el-button>
            <el-button>末页</el-button>
          </div>
        </div> -->
        <pagination :handleQuery="handleQuery" :currentPage="query.currentPage" :currentLength="query.currentLength"
          :total="total">
        </pagination>
      </div>
    </div>
    <Add v-if="isShow == 1" :onClose="onClose"></Add>
  </div>
</template>
<script>
import pagination from "@/components/pagination/index.vue";
import { getDate } from "@/until/utilse.js";
import { back_bucket_lst, back_bucket } from "@/api/salesManagement/manualDeclaration.js";
import Add from "./add.vue";
export default {
  async created() {
    await this.handleQuery();
  },
  components: { Add, pagination },
  data() {
    return {
      total: 0,
      isShow: 0,
      order_status_options: ["未确认", "已派单", "已送达", "已完成"],
      t1: '',
      query: {
        "commercial_id": localStorage.getItem("commercial_id") ||
          sessionStorage.getItem("commercial_id"),
        "firstTime": null,
        "lastTime": null,
        "order_status": "",
        "search": "",

        "currentPage": 1,
        "currentLength": 10
      },
      creatTime: "",

      option1: "",
      option1es: "",
      QueryContent: "",

      radioBtn: "全部",
      radioBtnes: ["全部", "未确认", "已派单", "已送达", "已完成"],

      tableData: [],
      multipleSelection: [],
      isResult: false,
      tableFinshNum: 0,
    };
  },
  mounted() {
    if (this.tableData.length == 0) {
      this.isResult = false;
    } else {
      this.isResult = true;
      this.tableFinshNum = this.tableData.length;
    }
  },
  computed: {},
  methods: {
    onClose() {
      this.isShow = 0
    },
    async handleAdd() {
      this.isShow = 1
    },
    reload() {
      location.reload();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 处理查询接口
    async handleQuery(page = 1) {
      if (this.t1) {
        var [firstTime, lastTime] = this.t1;
        firstTime = getDate(firstTime);
        lastTime = getDate(lastTime);
      }
      this.query = {
        ...this.query, firstTime, lastTime
      }
      this.query.currentPage = page
      let { data: { data, count } } = await back_bucket_lst(this.query);
      // let res = (await back_bucket_lst(this.query)).data;
      // console.log(res);
      // let count = res.count;
      // let data = res.data;
      // console.log(this.query)
      this.total = count
      this.tableData = data
      // console.log(data)
      // console.log(this.tableData)
      // console.log("aaa")
    }
  },
};
</script>
<style scoped lang="less">
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/deep/ .el-col {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-size: 14px;
  margin: 0 5px;
}

/deep/ .cell {
  padding: 0 !important;
  white-space: nowrap !important;
}

/deep/ .el-radio-button__inner {
  border: 0 !important;
  border-radius: 5px !important;
  background-color: #e6e6e6 !important;
}

/deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
  background-color: #409eff !important;
  box-shadow: none !important;
}
</style>
